/* GLOBAL STYLES */

@font-face {
  font-family: 'Monument';
  src: url("../fonts/MonumentExtended-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

:root {
  --accent: #448FDE;
  --accent-linear: linear-gradient(180.75deg, #448FDE 0%, #56A1F1 100%);
  --white: #fff;
  --dark: #262626;
  --dark-grey: #333333;
  --light-grey: #A3A3A3;
  --grey: #C4C4C4;
  --font-base: #000;
  --light-mid: #EAF0F6;
  --light-border: #F4F7FA;
  --square-accent: linear-gradient(45deg, #448FDE 0%, #56A1F1 100%);
  --square-dark: linear-gradient(315deg, #262626 0%, #545454 100%);
  --square-light: #fff;
  --font-faded: #E1EBF4;
  --accent-dot: linear-gradient(45deg, #448FDE 0%, #56A1F1 100%);
  --header-font: #1D1D1D;
}

body {
  color: var(--font-base);
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 1.625;
  font-weight: 400;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Monument', sans-serif;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0;
  font-weight: 400;
  margin: 0 0 1.625rem;
}

h1,
.h1 {
  font-size: 2.625rem;
}

h2,
.h2 {
  font-size: 3rem;
}

h3,
.h3 {
  font-size: 2.25rem;
}

h4,
.h4 {
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
}

h5,
.h5 {
  font-size: 1rem;
}

h6,
.h6 {
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  letter-spacing: -0.44px;
  line-height: 1.2;
  font-weight: bold;
}

p {
  margin-top: 0;
}

section,
nav,
header,
footer,
article {
  width: 100%;
}

img {
  aspect-ratio: attr(width) / attr(height);
}

a,
a:link,
a:visited,
a:hover {
  text-decoration: none;
  cursor: pointer;
  transition-duration: 200ms;
}

a:hover {
  opacity: .85;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-section {
  padding: 7.4rem 0;
}

.main-section.dark {
  background: var(--light-mid);
}

.section-center {
  width: 100%;
  max-width: 1252px;
  margin: 0 auto;
  padding: 0 3rem;
}

.section-title:before,
.section-title:after {
  display: block;
  content: '';
  margin-bottom: 2.25rem;
  height: 8px;
  width: 2.5rem;
  background: linear-gradient(90deg, #262626 0%, #262626 50%, #478ffb 50%, #478ffb 100%);
}

.section-title:after {
  display: none;
}

.section-title.after:after {
  display: block;
  margin: 1.5rem 0;
}

.section-title.after:before {
  display: none;
}

.section-title.after {
  margin-bottom: 0;
}

.section-title.subtitle:before {
  margin-bottom: 1.5rem;
}

.section-title .subtitle {
  display: block;
  margin-bottom: 1.5rem;
}

.section-title--center,
.section-title--faded {
  display: block;
  margin: 0 auto 3.75rem;
  text-align: center;
  font-size: 2.25rem;
}

.section-title--faded {
  color: var(--font-faded);
}

.section-title--faded>span {
  display: block;
}

.section-title--vertical {
  display: block;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  padding-top: 3rem;
}

.section-title--vertical:after {
  display: block;
  content: '';
  margin: 0 1.5rem;
  width: .5rem;
  height: 2.5rem;
  background: linear-gradient(0, #262626 0%, #262626 50%, #478ffb 50%, #478ffb 100%);
}

.cta {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.125rem;
  letter-spacing: 0;
  line-height: 1.15;
  color: var(--white) !important;
  opacity: 1 !important;
  background: linear-gradient(180deg, #545454 0%, #262626 100%);
  padding: 0.75rem 3.75rem 0.75rem 1.125rem;
  cursor: pointer;
  transition: background 200ms ease-in-out;
}

.cta--reversed {
  background: linear-gradient(43.01deg, #448FDE 42.23%, #56A1F1 82.48%);
}

.cta.accent {
  background: var(--accent);
}

.cta:hover {
  background: linear-gradient(180deg, #545454 0%, #424242 100%);
}

.cta.accent:hover {
  background: var(--accent);
}

.cta--reversed:hover {
  background: linear-gradient(43.01deg, #448FDE 42.23%, #56A1F1 82.48%);
}

.cta:before {
  display: block;
  content: '';
  position: absolute;
  width: 2.625rem;
  background-color: var(--accent);
  background-image: url("../img/arrow-right.svg");
  background-position-y: center;
  background-position-x: calc(100% - 0.75rem);
  background-repeat: no-repeat;
  right: 0;
  top: 0;
  bottom: 0;
  transition: width 200ms ease-in-out;
}

.cta--reversed:before {
  background-color: var(--dark);
}

.cta.accent:before {
  background-color: var(--accent);
}

.cta--reversed.light:before {
  background-color: var(--white);
  background-image: url("../img/arrow-right-dark.svg");
}

.cta:hover:before {
  width: 30%;
}

.cta.accent:hover:before {
  width: 2.625rem;
}

/* CEO QUOTE SECTION */

.ceo-quote {
  width: 100%;
  max-width: 1400px;
  margin: 3rem auto;
  padding-left: 6rem;
  height: 40.375rem;
  position: relative;
  overflow-x: hidden;
}

.ceo-quote__background {
  height: 100%;
  width: calc(100% - 21rem);
  content: '';
  background: linear-gradient(43.01deg, #448FDE 42.23%, #56A1F1 82.48%);
  position: absolute;
}

.ceo-quote__background::after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  background-color: var(--white);
  width: 6rem;
  height: 6rem;
}

.ceo-quote__container {
  position: absolute;
  top: 3rem;
  left: 9rem;
  width: calc(100% - 9rem);
  height: 34.375rem;
  background: linear-gradient(92.27deg, rgba(68, 143, 222, 0.1) -1.01%, rgba(68, 143, 222, 0) 80.01%), linear-gradient(311.02deg, #262626 39.12%, #545454 115.59%);
  display: flex;
  flex-direction: row;
}

.ceo-quote__container::after {
  position: absolute;
  bottom: 0;
  right: 0;
  content: '';
  background: url("../img/career/quote-background-tiles.svg");
  height: 15rem;
  width: 18rem;
}

.ceo-quote__image {
  width: 35%;
  height: fit-content;
  position: absolute;
  bottom: 0;
  left: -3%;
  filter: grayscale(100%);
}

.ceo-quote__content {
  padding-left: 36%;
  padding-top: 9.875rem;
  display: flex;
  flex-direction: column;
  color: var(--white);
}

.ceo-quote__text {
  line-height: 130%;
  max-width: 86%;
  position: relative;
  margin: 0;
}

.ceo-quote__text::before {
  position: absolute;
  background: url("../img/career/quotation-mark.svg");
  content: '';
  height: 3.25rem;
  width: 3.6875rem;
  top: 0.3125rem;
  left: -6.25rem;
}

.ceo-quote__name {
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 130%;
  margin-top: 2.5625rem;
  margin-bottom: 0;
}

.ceo-quote__position {
  font-size: 1.25rem;
  line-height: 130%;
  margin-top: 0.125rem;
  margin-bottom: 0;
}


/* CLIENT TESTIMONIALS SECTION */

.client-testimonials {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: hidden;
  color: var(--dark);
}

.client-testimonials__icon {
  width: 5.9375rem;
  height: fit-content;
  margin: 0.1rem 4.75rem 0 4rem;
}

.client-testimonials__text {
  font-size: 1.75rem;
  line-height: 130%;
  margin-bottom: 3rem;
  max-width: 57.5rem;
}

.client-testimonials__author {
  display: flex;
  flex-direction: row;
}

.client-testimonials .author__image {
  width: 5rem;
  height: 5rem;
}

.client-testimonials .author__content {
  max-width: 51.25rem;
  display: flex;
  flex-direction: column;
  line-height: 150%;
  margin-left: 1.25rem;
  justify-content: center;
}

.client-testimonials .author__name {
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: .084375rem;
}

.client-testimonials .author__title {
  font-size: 1.125rem;
  color: var(--dark-grey);
  margin-bottom: 0;
}


/* RECTANGLES */

.rectangles {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.rectangles__item {
  padding: 1.75rem 2rem;
  height: 16.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  transition: transform 150ms ease-in-out 0ms;
}

.rectangles__item:hover {
  transform: translateY(-1rem);
  opacity: 1;
}

.rectangles__item.accent {
  background: var(--square-accent);
  color: var(--white);
}

.rectangles__item.dark {
  background: var(--square-dark);
  color: var(--white);
}

.rectangles__item-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.rectangles__item-title {
  font-weight: 600;
  font-size: 24px;
}

.rectangles__item-text {
  max-width: 27.5rem;
  font-size: 18px;
}


/* HOW WE SECTION */

.how-we-recruit {
  position: relative;
  overflow-x: hidden;
  padding-bottom: 3.125rem;
}

.how-we-recruit__container {
  background:
    linear-gradient(92.27deg, rgba(68, 143, 222, 0.1) -1.01%, rgba(68, 143, 222, 0) 80.01%),
    linear-gradient(311.02deg, #262626 39.12%, #545454 115.59%);
  color: var(--white);
}

.how-we-recruit__container.light {
  background: linear-gradient(240.98deg, rgba(234, 240, 246, 0.109375) -6.98%, #EAF0F6 32.21%, #EAF0F6 81.54%);
  color: var(--dark);
}

.how-we-recruit__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.how-we-recruit::before {
  position: absolute;
  top: 2.5625rem;
  left: 0;
  content: '';
  background: url("../img/career/how-we-recruit-tiles-top.svg");
  height: 18.75rem;
  width: 15.625rem;
  z-index: 1;
}

.how-we-recruit__tiles--bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  content: '';
  background: url("../img/career/how-we-recruit-tiles-bottom.svg");
  height: 12.5rem;
  width: 18.75rem;
}

.how-we-recruit__icon {
  margin-top: 10rem;
  margin-bottom: 2rem;
}

.how-we-recruit__header {
  text-align: center;
}

.how-we-recruit__kicker {
  font-size: 1.375rem;
  text-align: center;
  margin-bottom: 4.375rem;
  max-width: 31.75rem;
}

.how-we-recruit__items {
  display: flex;
  flex-direction: row;
  padding-bottom: 4.375rem;
}

.how-we-recruit__items--left,
.how-we-recruit__items--right {
  flex: 1 1 50%;
}

.how-we-recruit__items .item {
  padding-left: 4.375rem;
  display: flex;
  flex-direction: row;
  height: 11.25rem;
}

.how-we-recruit__items .item__number {
  font-family: 'Monument';
  font-size: 1.125rem;
  color: var(--light-grey);
}

.how-we-recruit__items .item__number.light {
  color: #C8D3DE;
}

.how-we-recruit__items .item__content {
  display: flex;
  flex-direction: row;
}

.how-we-recruit__items .item__icon {
  width: 5.875rem;
  height: 5.875rem;
  margin: 0 2rem;
}

.how-we-recruit__items .item__text {
  max-width: 20rem;
}


/* G-CORE SECTION */

.g-core__wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
}

.g-core__wrapper:before {
  position: absolute;
  content: '';
  width: 9.375rem;
  height: 25rem;
  top: 5.25rem;
  left: -5.875rem;
  background-image: url("../img/services/services-core-pixels-left.svg");
}

.g-core__column {
  width: 50%;
}

.g-core__column.left {
  padding: 5rem 0 0 5rem;
  z-index: 1;
}

.g-core__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 1.125rem;
  margin-bottom: 3.125rem;
}

.g-core__header-text--before {
  padding-top: .46875rem;
  padding-right: 1.375rem;
}

.g-core__header-text--after {
  padding-top: .46875rem;
  padding-left: .9375rem;
}

.g-core__text {
  max-width: 20.625rem;
  font-size: 1.375rem;
  line-height: 2.0625rem;
  margin-bottom: 2.25rem;
}

.accordion {
  overflow: hidden;
  width: 31.5rem;
  margin: 0 auto;
  box-shadow: 0.625rem 0.625rem 0.625rem rgba(235, 241, 246, 0.5);
}

.accordion__item {
  width: 100%;
  overflow: hidden;
}

.accordion__label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 3.0625rem 4.5rem;
}

.accordion__item.dark {
  background: linear-gradient(311.02deg, #262626 39.12%, #545454 115.59%);
  color: var(--white);
}

.accordion__item.accent {
  background: linear-gradient(43.01deg, #448FDE 42.23%, #56A1F1 82.48%);
  color: var(--white);
}

.accordion__item.light {
  background: white;
  color: var(--dark);
}

.accordion__subheader {
  margin-bottom: 0;
}

.accordion__content {
  max-height: 0;
  padding: 0 3.125rem 0 4.5rem;
  transition: all 0.3s;
}

.accordion__content p {
  margin: 0;
  font-size: 1.125rem;
}

.accordion input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.accordion input:checked ~ .accordion__content {
  max-height: 100vh;
  padding: .625rem 3.125rem 2.8125rem 4.5rem;
}

/* NAVIGATION MENU */

nav {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: var(--white);
}

.nav-dark {
  background-color: transparent;
  transition: background-color 0.3s;
}

.nav-dark .menu__logo {
  filter: grayscale(1) invert(1);
  transition: color 0.3s;
}

.nav-dark .menu__item-link,
.nav-dark .menu__item-link:active,
.nav-dark .menu__item-link:hover {
  color: var(--white);
  transition: color 0.3s;
}

.nav-scrolled {
  background-color: var(--white);
}

.nav-scrolled .menu__logo {
  filter: grayscale(1) invert(0);
  transition: color 0.3s;
}

.nav-scrolled .menu__item-link,
.nav-scrolled .menu__item-link:active,
.nav-scrolled .menu__item-link:hover {
  color: var(--dark);
}

.menu {
  height: 8.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.menu__logo {
  flex-shrink: 0;
  flex-grow: 0;
  display: block;
  cursor: pointer;
}

.menu__icon,
.menu__btn {
  display: none;
}

.menu__right {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 2.25rem;
}

.menu__items {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  align-self: stretch;
  list-style: none;
  font-size: 1.125rem;
  letter-spacing: -0.4px;
  line-height: 1.22;
  margin: 0;
  column-gap: 2rem;
}

.menu__item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.menu__item--socials,
.menu__item--mobile {
  display: none;
}

.menu__getquote {
  display: flex;
  align-items: center;
  color: var(--white);
  order: 2;
}

.menu__getquote > .left {
  background: var(--square-dark);
  padding: 12.5px 13px;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: normal;
}

.menu__getquote > .right {
  background: var(--accent);
  padding: 12.5px 13px;
  display: flex;
  align-items: center;
}

.menu__getquote > .right > .arrow--right {
  width: 13px;
  height: 14px;
}

.menu__item-link,
.menu__item-link:active,
.menu__item-link:hover {
  padding-top: 0.625rem;
  color: var(--dark);
}

.menu__item-link:after {
  display: block;
  content: '';
  margin-top: 0.625rem;
  border-bottom: 2px solid transparent;
  border-bottom-color: var(--accent);
  transform: scaleX(0);
  transition: transform 150ms ease-in-out;
}

.menu__item:hover>.menu__item-link:after {
  transform: scaleX(1);
}

.menu__button {
  width: max-content;
}

.menu__button--mobile {
  display: none;
}


/* PAGE HEADER */

.header {
  position: relative;
  margin-bottom: 11.875rem;
}

.header__container:before {
  display: block;
  content: '';
  position: absolute;
  bottom: -3rem;
  right: 0;
  top: 3rem;
  left: 15rem;
  background: var(--accent);
}

.header__background {
  display: block;
  position: relative;
  width: 100%;
}

.header__corner {
  position: absolute;
}

.header__corner.rt {
  top: 0;
  right: 3rem;
}

.header__corner.rb {
  bottom: 0;
  right: 3rem;
}

.header__corner.white {
  background-color: var(--white);
  right: 0;
  bottom: -3rem;
  width: 12rem;
  height: 12rem;
}

.header__content {
  position: absolute;
  left: 3rem;
  bottom: 0;
  background-color: var(--white);
  padding: 5.5rem 4rem 4.375rem 0;
  width: 31.25rem;
}

.header__text {
  font-size: 1.375rem;
  letter-spacing: -0.5px;
  line-height: 1.5;
  margin: 0;
}

/* ================================================ */
/* HOW CAN WE HELP*/

.squares {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  overflow: visible;
}

.squares__item {
  padding: 1.75rem 2rem;
  height: 14.5rem;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  opacity: 1 !important;
  transition: transform 150ms ease-in-out 0ms !important;
}

.squares__item:hover {
  transform: translateY(-1rem);
}

.squares__item.accent {
  background: var(--square-accent);
  color: var(--white) !important;
}

.squares__item.light {
  background: var(--square-light);
  color: var(--dark) !important;
}

.squares__item.dark {
  background: var(--square-dark);
  color: var(--white) !important;
}

.squares__item.dark img {
  position: absolute;
}

.squares__item.accent img {
  position: absolute;
}

.squares__item-title {
  align-self: flex-start;
  max-width: 60%;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: -0.4px;
  line-height: 1.22;
}

.squares__item-text {
  align-self: flex-start;
  max-width: 85%;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.6875rem;
}

/* RECTANGLES */

.rectangles {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.rectangles__item {
  padding: 1.75rem 2rem;
  height: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  transition: transform 150ms ease-in-out 0ms;
}

.rectangles__item:hover {
  transform: translateY(-1rem);
  opacity: 1;
}

.rectangles__item.accent {
  background: var(--square-accent);
  color: var(--white);
}

.rectangles__item.dark {
  background: var(--square-dark);
  color: var(--white);
}

.rectangles__item-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 3.125rem;
}

.rectangles__item-title {
  font-weight: 600;
  font-size: 1.5rem;
}

.rectangles__item-text {
  max-width: 27.5rem;
  font-size: 1rem;
}

/* CASE STUDIES */

#case-studies>.section-center {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding-right: 0;
  max-width: 1440px;
  width: 100%;
}

#case-studies .section-title--vertical {
  margin-bottom: 0;
}

.studies {
  display: none;
  margin-bottom: -2rem;
  padding-bottom: 2rem;
}

.studies.for-poland {
  display: block;
}

.studies__item {
  position: relative;
  color: var(--white);
  text-decoration: none;
}

.studies__item>* {
  color: var(--white);
}

.studies__item::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-image: linear-gradient(180deg, transparent, #218EFF 69%);
  cursor: pointer;
  transition: opacity .25s ease;
}

.studies__item:hover {
  opacity: 1;
}

.studies__item:hover::after {
  opacity: 0.55;
}

.studies__item-bg {
  width: 100%;
  height: auto;
  z-index: -1;
}

.studies__item-logo {
  position: absolute;
  top: 0;
  right: 0;
  padding: 3rem 3rem 0;
  z-index: 1;
}

img.studies__item-logo {
  width: auto;
  height: auto;
}

.studies__item-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 2.75rem 2.75rem;
  z-index: 1;
}

.studies__item-title {
  line-height: 1.25;
  margin-bottom: 0.625rem;
}

.studies__item-subtitle {
  font-size: 1.125rem;
  letter-spacing: -0.4px;
  line-height: 1.22;
  margin: 0;
}

.learn-more {
  display: flex;
  justify-content: flex-start;
  flex-direction: column-reverse;
  background-color: var(--font-base);
  padding: 3.5rem 2.5rem;
}

.learn-more__icon {
  width: 1.5rem;
  height: 1.5rem;
  transform: rotate(-90deg);
  margin-top: 1rem;
}

.learn-more__text {
  font-weight: 700;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  color: var(--white);
}

.studies .swiper-pagination {
  text-align: right;
}

.studies .swiper-pagination-bullet {
  border-radius: unset;
  margin: 0 0.75rem !important;
}

.studies .swiper-pagination-bullets {
  bottom: 0;
}

.studies .swiper-pagination-bullet-active {
  background: var(--dark);
}

.studies .swiper-button-next,
.studies .swiper-button-prev {
  top: calc(50% - 2rem);
}

.studies .swiper-button-next:after {
  content: '';
  background-image: url("../img/case-studies-swiper-arrow-right.svg");
  width: .6875rem;
  height: 1.0625rem;
}

.studies .swiper-button-prev:after {
  content: '';
  background-image: url("../img/case-studies-swiper-arrow-left.svg");
  width: .6875rem;
  height: 1.0625rem;
}

/* REVIEWS */

.reviews {
  overflow-y: visible;
}

.reviews__container {
  margin: 0 auto;
  max-width: 90rem;
}

.reviews__block {
  margin-right: 5vw;
  max-width: 90rem;
  padding: 5.9375rem 2.5rem;
  background: linear-gradient(240.98deg, rgba(234, 240, 246, 0.109375) -6.98%, #EAF0F6 32.21%, rgba(234, 240, 246, 0) 81.54%);
  position: relative;
}

.reviews__block:after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  height: 28.125rem;
  width: 47vw;
  background: radial-gradient(50% 50% at 50% 50%, #2F4E6D 0%, rgba(109, 121, 136, 0) 86.46%);
  opacity: 0.25;
  filter: blur(9.375rem);
  transform: rotate(-180deg);
}

.reviews .swiper-slide-active {
  padding-left: 3.75rem;
  padding-right: 1.875rem;
}

.reviews .swiper-slide-next {
  padding-left: 1.875rem;
  padding-right: 3.75rem;
}

.reviews .swiper-pagination {
  text-align: right;
  padding-right: 3rem;
}

.reviews .swiper-pagination-bullet {
  border-radius: unset;
  margin: 0 0.75rem !important;
}

.reviews .swiper-pagination-bullets {
  bottom: 0;
}

.reviews .swiper-pagination-bullet-active {
  background: var(--dark);
}

.reviews__item-wrapper {
  padding: 2.5rem 0;
}

.reviews__item {
  background-color: var(--white);
  padding: 5rem 3.375rem 3.375rem;
  height: 20.9375rem;
  position: relative;
  color: var(--dark);
}

.reviews__item-icon {
  position: absolute;
  top: -1.5rem;
  left: 3.375rem;
}

.reviews__item-quote {
  margin: 0 0 1.25rem;
}

.reviews__item-container {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.reviews__item-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: .25rem;
}

.reviews__item-author {
  display: block;
  font-weight: 600;
  margin-bottom: 0.125rem;
}

.reviews__item-note {
  font-size: 0.875rem;
  letter-spacing: -0.2px;
  line-height: 1.2;
}

.reviews .swiper-button-next:after {
  content: '';
  background-image: url("../img/reviews-swiper-arrow-right.svg");
  width: .6875rem;
  height: 1.0625rem;
}

.reviews .swiper-button-prev:after {
  content: '';
  background-image: url("../img/reviews-swiper-arrow-left.svg");
  width: .6875rem;
  height: 1.0625rem;
}

.reviews__widget {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.625rem;
  padding-left: 3.75rem;
}

.reviews__widget-text {
  color: #000000;
  opacity: 0.7;
}

/* CUSTOMERS */

.customers {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 3.375rem 3.375rem;
  row-gap: 2.5rem;
}

.customers__item {
  display: block;
  width: 100%;
  max-width: 8.25rem;
  height: 100%;
  object-fit: contain;
  object-position: center;
  transition-duration: 250ms;
  margin: auto;
}

.customers__item:hover {
  transform: scale(1.2);
}

/* WE MAKE IT BETTER */

.it-better {
  padding: 3rem;
  position: relative;
}

.it-better__background {
  background: var(--accent-linear);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 15rem;
}

.it-better__photo {
  width: 100%;
  height: auto;
  position: relative;
}

.it-better__corner {
  position: absolute;
}

.it-better__corner.rt {
  right: 3rem;
  top: 3rem;
}

.it-better__corner.lb {
  bottom: 3rem;
  left: 31.25rem;
}

.it-better__white-block {
  width: 12rem;
  height: 12rem;
  background: var(--white);
  position: absolute;
  right: 0;
}

.it-better__white-block.top {
  top: 0;
}

.it-better__white-block.middle {
  top: calc(3 * 12rem);
}

.it-better__content {
  position: absolute;
  left: 3rem;
  bottom: 3rem;
  background-color: var(--white);
  padding: 5.5rem 4rem 4.375rem 0;
  width: 31.25rem;
}

/* TECH STACK */

#tech-stack {
  background: linear-gradient(240.98deg, rgba(234, 240, 246, 0.109375) -6.98%, #EAF0F6 32.21%, rgba(234, 240, 246, 0) 81.54%);
}

.tech-stack {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 4.25rem 4.25rem;
  row-gap: 2.5rem;
  max-width: 800px;
  margin: 0 auto;
}

.tech-stack__item {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  transition-duration: 250ms;
  margin: auto;
}

.tech-stack__item:hover {
  transform: scale(1.2)!important;
}


/* BOXES */
.boxes {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 1.5rem;
  grid-row-gap: 2.3125rem;
  position: relative;
}

.boxes .box {
  width: 13.25rem;
  height: 23.9375rem;
  padding: 1.3125rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.boxes .box.accent {
  background: linear-gradient(43.01deg, #448FDE 42.23%, #56A1F1 82.48%);
  color: var(--white);
}

.boxes .box.dark {
  background: linear-gradient(311.02deg, #262626 39.12%, #545454 115.59%);
  color: var(--white);
}

.boxes .box.light {
  background: var(--white);
  color: var(--dark);
}

.boxes .box__header {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.375rem;
  height: 8.625rem;
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.125rem;
  text-transform: none;
}

.boxes .box__text {
  line-height: 150%;
}

.boxes .box__image {
  height: 60px;
}


/* EXPERTS */

.experts {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: stretch;
  position: relative;
  padding-bottom: 3rem;
}

.experts__content {
  background-color: var(--white);
  padding: 5.5rem 4rem 4.375rem 0;
  max-width: 50%;
}

.experts__container {
  width: 50%;
}

.experts__photo {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.experts__background {
  display: block;
  content: '';
  position: absolute;
  top: 6rem;
  right: 0;
  bottom: 0;
  width: 29.5rem;
  background: var(--accent-linear);
}

.experts__corner {
  position: absolute;
}

.experts__corner.rb {
  right: 0;
  bottom: 0;
}

/* TECHNOLOGY */

.technology {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 4.375rem;
}

.technology__item {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  max-width: 11.25rem;
  margin: auto;
}

/* CLIENT TESTIMONIALS */

.testimonials {
  display: flex;
  gap: 4.75rem;
  padding-left: 7.063rem;
}

.testimonials__quotation {
  margin-top: 2.675rem;
}

.testimonials__quote {
  font-size: 1.75rem;
  margin-bottom: 3rem;
}

.testimonials__author {
  display: flex;
  gap: 1.25rem;
}

.testimonials__author--right {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.author__name {
  font-weight: 700;
  font-size: 1.375rem;
}

.author__title {
  font-size: 1.25rem;
}

.testimonials__author--right p {
  margin-bottom: 0;
}

/* CONTACT */

.contact {
  position: relative;
  height: 41.625rem;
  display: flex;
}

.contact:before {
  background: linear-gradient(240.98deg, rgba(234, 240, 246, 0.109375) -6.98%, #EAF0F6 32.21%, rgba(234, 240, 246, 0) 81.54%);
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  height: 31.9375rem;
  width: 100%;
  z-index: -1;
}

.contact__image {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.contact__image img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: auto;
}

.contact__content {
  padding: 13.9375rem 3.125rem 0 6.625rem;
  width: 50%;
}


/* ================================================ */
/* FOOTER */

footer {
  background-color: var(--dark);
  padding: 6rem 0;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: nowrap;
  color: var(--white);
  font-size: 0.875rem;
  letter-spacing: -0.2px;
  line-height: 1.5;

}

.footer__details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
}

.footer__details * {
  display: block;
  margin: 0;
}

.footer__details-logo {
  margin-bottom: 1.625rem;
}

.footer__socials {
  display: flex;
  flex-flow: row nowrap;
  font-size: 1.5rem;
}

a.footer__socials-item,
a.footer__socials-item:link,
a.footer__socials-item:hover {
  color: var(--white);
  margin-right: 1rem;
  width: 1.5rem;
  text-align: center;
}

a.footer__socials-item:last-of-type {
  margin-right: 0;
}

.footer__sections {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  flex-grow: 1;
}

.footer__section {
  width: 100%;
  max-width: 15.625rem;
  display: flex;
  flex-direction: column;
}

.footer__section:last-child {
  text-align: right;
}

.footer__section-item {
  margin-bottom: 1.25rem;
}

.footer__section-item:last-child {
  margin-bottom: 0;
}

.footer__section-item * {
  display: block;
  margin: 0;
}

.footer-menu__items {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  row-gap: 0.4rem;
}

.footer-menu__item-link {
  color: var(--white);
}

.footer__header-mobile {
  display: none;
}

.footer a,
.footer a:link,
.footer a:visited,
.footer a:hover {
  color: var(--white);
}

a.footer__email,
a.footer__email:link,
a.footer__email:visited,
a.footer__email:hover {
  color: var(--accent);
}

.for-abroad-hidden {
  display: none;
}

.for-abroad-visible {
  display: block;
}

/* Map and Info window */

#map {
  height: 25rem;
  width: 100%;
}

.info-window__caption {
  color: #000000;
  margin: 0 0 3px;
  padding: 0.125rem;
  font: bold 13px/1.2 Inter;
  text-align: center;
  width: 100%;
}

.info-window__label {
  color: #000000;
  font: 9px/1.2 Inter;
  text-align: left;
  width: 100%;
  margin-bottom: .3125rem;
}

.info-window__label-rating {
  color: #000000;
  font: 9px/1.2 Inter;
  text-align: left;
  margin-bottom: .3125rem;
  margin-right: .625rem;
}

.info-window__link {
  color: #1B74E8;
  font: 10px/1.2 Inter;
  text-align: left;
  width: 100%;
  margin-bottom: .3125rem;
}

.rating-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.checked {
  color: orange;
}

.stars {
  display: flex;
}

.tippy-content a {
  color: white;
  text-decoration: underline;
}

.checkbox__info-icon {
  vertical-align: text-bottom;
  cursor: pointer;
}
